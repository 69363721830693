<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::TwilioIntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="accountId">
    <label for="accountId">{{ 'Settings::TwilioIntegrationAccountSid' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="accountId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="authToken">
    <label for="authToken">{{ 'Settings::TwilioIntegrationAuthToken' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="authToken"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="workspaceId">
    <label for="workspaceId">{{ 'Settings::TwilioIntegrationWorkspaceId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="workspaceId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="migrationStartDate">
    <label for="migrationStartDate">{{ 'Settings::TwilioIntegrationMigrationStartDate' | abpLocalization }}</label>
    <input
      type="text"
      placeholder="2023-01-31"
      class="form-control form-control-sm"
      id="migrationStartDate"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
