import { Injectable, Injector } from '@angular/core';
import { MapToServerSideEnum } from 'src/core/decorators/enum/mapto-server-side-enum.decorator';
import { ServerSideEnum } from '../enum/server-side-enum.model';

@Injectable({
  providedIn: 'root',
})
@MapToServerSideEnum('QueryFieldDataType')
export class QueryFieldDataType extends ServerSideEnum {
  Operator: number;
  OrderedGroup: number;
  OrderedGroupWithRange: number;
  QueryGroup: number;
  SimpleTerm: number;
  SimpleTermWithNot: number;
  SimpleTermWithOccurence: number;
  SimpleTermWithRange: number;
  SimpleTermWithRangeAndOccurence: number;
  NearQuery: number;
  OrderedSimpleTerm: number;
  Integer: number;
  String: number;
  Boolean: number;
  BigInteger: number;
  Time: number;
  MergeQuery: number;
  AttachedData: number;
  QueryParser: number;
  Tag: number;
  NegativeContent: number;
  NearQueryWithRange: number;
  ConversationNer: number;
  ConversationNerWithValue: number;
  ConversationNerWithRange: number;
  GenAIYesNo: number;
  GenAIYesNoWithRange: number;

  constructor(injector: Injector) {
    super(injector);
  }
}
